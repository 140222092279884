import { createRouter, createWebHistory, RouteRecordRaw, RouteRecordName } from 'vue-router';
import GAnalytics from '../utils/GAnalytics';

const routes: Array<RouteRecordRaw> = [
   {
      path: '/:campaignSlug?/:entryCode?',
      name: 'EntryCodePage',
      component: () => import(/* webpackChunkName: "EntryCodePage" */ '../pages/EntryCodePage.vue'),
   },
   {
      path: '/:campaignSlug/entry',
      name: 'CampaignPage',
      component: () => import(/* webpackChunkName: "CampaignPage" */ '../pages/CampaignPage.vue'),
   },
   {
      path: "/error/campaign/entry-limit-exceeded",
      name: 'EntryLimitExceededPage',
      component: () => import(/* webpackChunkName: "EntryLimitExceededPage" */ '../pages/EntryLimitExceededPage.vue'),
   },
   {
      path: "/error/campaign/not-found",
      name: '404',
      component: () => import(/* webpackChunkName: "404Page" */ '../pages/CampaignExpiredPage/404.vue'),
   },
];

const router = createRouter({
   history: createWebHistory(import.meta.env.BASE_URL),
   routes
})

router.beforeEach(async (to, from, next) => {
   // log all page views by route
   GAnalytics.logRoute(to);

   if (to.name === 'EntryLimitExceededPage') {
      if (window.localStorage.getItem('exceeded') && typeof (window.localStorage.getItem('exceeded')) === 'string' && window.localStorage.getItem('exceeded') !== '') {
         next()
         return;
      }
   }

   if (from.path === '/' && to.name !== 'EntryCodePage' && to.name !== '404') {
      next({
         name: 'EntryCodePage',
         params: {
            campaignSlug: to.params.campaignSlug && to.params.campaignSlug !== '' ? to.params.campaignSlug : 'not-found'
         },
      })
      return;
   }

   next();
})

export {
   router,
}
