export async function createFingerprint(): Promise<string> {
   const fingerprintArray: string[] = [];

   // User Agent String
   fingerprintArray.push(navigator.userAgent);

   // screen dimensions
   fingerprintArray.push(`${screen.width}x${screen.height}`);

   // Device Type
   fingerprintArray.push(getDeviceType());

   // Web Audio
   fingerprintArray.push(hasWebAudio().toString());

   // Is HDR
   fingerprintArray.push(isHDR().toString());

   // Canvas Fingerprinting
   const canvas = document.createElement('canvas');
   const context = canvas.getContext('2d');
   if (context) {
      const text = 'fingerprint';
      context.fillText(text, 0, 0);
      const canvasData = canvas.toDataURL();
      fingerprintArray.push(canvasData);
   }

   // installed fonts
   try {
      const fonts = await getInstalledFonts();

      fingerprintArray.push(fonts);
   } catch (err) {
      console.log('');
   }

   // Timezone
   fingerprintArray.push(userTimezone());

   // Touch Support
   fingerprintArray.push(hasTouchSupport().toString());

   // Cookies Enabled
   fingerprintArray.push(areCookiesEnabled().toString());

   // langauge preferences
   if (navigator.language && navigator.language !== '') {
      fingerprintArray.push(navigator.language);
   }

   // languages
   if (navigator.languages && navigator.languages.length > 0) {
      fingerprintArray.push(navigator.languages.join(','));
   }

   // Sensor Data (Accelerometer)
   try {
      const accelerometerData = await getAccelerometerData();
      fingerprintArray.push(`${accelerometerData.x},${accelerometerData.y},${accelerometerData.z}`);
   } catch (error) {
      fingerprintArray.push('0,0,0'); // Fallback value if sensor data is unavailable
   }

   // Data Storage Support
   fingerprintArray.push(isDataStorageSupported().toString());

   // Media Capabilities
   // try {
   //    const mediaCapabilities = await getMediaCapabilities();
   //    fingerprintArray.push(JSON.stringify(mediaCapabilities));
   // } catch (error) {
   //    fingerprintArray.push('{}'); // Fallback value if media capabilities cannot be retrieved
   // }

   // WebGL Capabilities
   const webGLCapabilities = getWebGLCapabilities();
   fingerprintArray.push(JSON.stringify(webGLCapabilities));

   // Hash the fingerprint array
   const fingerprintString = fingerprintArray.join('');
   const hashedFingerprint = await hashFunction(fingerprintString);

   return hashedFingerprint;
}

function getDeviceType(): string {
   const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
   const isTablet = /iPad/i.test(navigator.userAgent);
   if (isTablet) {
      return 'Tablet';
   } else if (isMobile) {
      return 'Mobile';
   } else {
      return 'Desktop';
   }
}

// check if the device has webAudio
function hasWebAudio(): boolean {
   return typeof window.AudioContext !== 'undefined';
}

// check if the device supports HDR
function isHDR(): boolean {
   if (typeof window.matchMedia === 'function') {
      return window.matchMedia('(hdr)').matches;
   }
   return false;
}

// check if the device has touch support
function hasTouchSupport(): boolean {
   return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
}

// check if cookies are enabled
function areCookiesEnabled(): boolean {
   return navigator.cookieEnabled !== false; // Returns true if cookieEnabled is not explicitly false
}

// get the users timezone
function userTimezone(): string {
   return new Intl.DateTimeFormat().resolvedOptions().timeZone;
}

// accelerometer data
async function getAccelerometerData(): Promise<{ x: number, y: number, z: number }> {
   return new Promise((resolve, reject) => {
      if ('Accelerometer' in window) {
         // @ts-ignore
         const sensor = new Accelerometer();

         let dataRead = false;

         sensor.onerror = (event: any) => {
            reject(event.error);
         };

         sensor.onreading = () => {
            if (!dataRead) {
               dataRead = true;
               resolve({ x: sensor.x, y: sensor.y, z: sensor.z });
            }
         };

         sensor.start();

         // Fallback: Reject the Promise if no data is read within a timeout
         const fallbackTimeout = setTimeout(() => {
            if (!dataRead) {
               dataRead = true;
               sensor.stop(); // Stop the sensor
               reject(new Error('Failed to read sensor data within timeout.'));
            }
         }, 200); // Set your desired timeout value in milliseconds

         // Clear the fallback timeout if data is successfully read
         sensor.addEventListener('reading', () => {
            clearTimeout(fallbackTimeout);
         });

      } else {
         reject(new Error('Accelerometer sensor is not supported.'));
      }
   });
}

// check if data storage is supported
function isDataStorageSupported(): boolean {
   try {
      // Check for IndexedDB support
      if ('indexedDB' in window) {
         const indexedDB = window.indexedDB || (window as any).mozIndexedDB || (window as any).webkitIndexedDB || (window as any).msIndexedDB;
         if (indexedDB) {
            // Open a test database
            const request = indexedDB.open('testDB');
            request.onerror = () => {
               return false;
            };
            request.onsuccess = () => {
               request.result.close(); // Close the test database
               return true;
            };
            return true;
         }
      }

      // Check for Local Storage support
      if ('localStorage' in window && window.localStorage !== null) {
         // Attempt to read and write from Local Storage
         const testKey = 'testKey';
         window.localStorage.setItem(testKey, 'testValue');
         window.localStorage.removeItem(testKey);
         return true;
      }
   } catch (error) {
      return false;
   }

   return false;
}

// media capabilities
async function getMediaCapabilities(): Promise<MediaCapabilitiesInfo | {}> {
   if ('mediaCapabilities' in navigator) {
      try {
         const mediaConfig: MediaDecodingConfiguration = {
            type: 'media-source',
            video: {
               contentType: 'video/mp4; codecs="avc1.42E01E, mp4a.40.2"',
               bitrate: 500000, // Desired bitrate in bits per second
               framerate: 30, // Desired framerate in frames per second
               height: 720, // Desired video height in pixels
               width: 1280 // Desired video width in pixels
            },
            audio: {
               contentType: 'audio/mpeg'
            }
         };

         const capabilities = await navigator.mediaCapabilities.decodingInfo(mediaConfig);

         return capabilities;
      } catch (error) {
         console.error('Error retrieving media capabilities:', error);
      }
   }

   return {};
}

// check webgl capabilities
interface WebGLCapabilities {
   vendor?: string;
   renderer?: string;
   extensions?: string[];
}

function getWebGLCapabilities(): WebGLCapabilities {
   const gl = getWebGLContext();

   if (!gl) {
      return {};
   }

   const extensionNames = getSupportedWebGLExtensions(gl);

   const capabilities: WebGLCapabilities = {
      vendor: gl.getParameter(gl.VENDOR),
      renderer: gl.getParameter(gl.RENDERER),
      extensions: extensionNames,
   };

   return capabilities;
}

// get webgl context
function getWebGLContext(): WebGLRenderingContext | null {
   const canvas = document.createElement('canvas');
   const contextNames = ['webgl', 'experimental-webgl', 'moz-webgl', 'webkit-3d'];

   let gl: WebGLRenderingContext | null = null;
   for (let i = 0; i < contextNames.length; i++) {
      try {
         gl = canvas.getContext(contextNames[i]) as WebGLRenderingContext;
      } catch (error) {
         continue;
      }
      if (gl) {
         break;
      }
   }

   return gl;
}

// get supported webgl extensions
function getSupportedWebGLExtensions(gl: WebGLRenderingContext): string[] {
   const supportedExtensions = gl.getSupportedExtensions();

   if (!supportedExtensions) {
      return [];
   }

   return supportedExtensions;
}

// get installed fonts
function getInstalledFonts(): Promise<string> {
   return new Promise((resolve, reject) => {
      try {
         document.fonts.ready.then(() => {
            const fontsList: string[] = [];
            document.fonts.forEach((i: FontFace) => {
               fontsList.push(i.family);
            })

            resolve(fontsList.join(','));
         });
      } catch (err) {
         resolve('')
      }
   })
}

// function hash the fingerprint
async function hashFunction(data: string): Promise<string> {
   const encoder = new TextEncoder();
   const dataBuffer = encoder.encode(data);
   const hashBuffer = await crypto.subtle.digest('SHA-256', dataBuffer);
   const hashArray = Array.from(new Uint8Array(hashBuffer));
   const hashHex = hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');
   return hashHex;
}