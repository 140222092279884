export const fontImporter = {
   url: (url = 'https://ajax.googleapis.com/ajax/libs/webfont/1.6.26/webfont.js') => {
      return new Promise((resolve, reject) => {
         const script = document.createElement('script')
         script.type = 'text/javascript'
         script.src = url
         script.addEventListener('load', () => resolve(script), false)
         script.addEventListener('error', () => reject(script), false)
         document.body.appendChild(script)
      })
   },
   urls: (urls: string[]) => {
      return Promise.all(urls.map(fontImporter.url))
   }
}