import { readonly, reactive, ref } from 'vue';

export interface ThemeDataAttrs {
   backgroundColor: string;
   backgroundImage: string;
   primary: string;
   secondary: string;
   accent: string;
   btnPrimary: string;
   btnSecondary: string;
   btnAccent: string;
   btnActive: string;
   btnDisabled: string;
   btnDisabledText: string;
   btnText: string;
   btnHover: string;
   btnHoverText: string;
   textPrimary: string;
   textSecondary: string;
   textAccent: string;
   textError: string;
   textPlaceholder: string;
   textInputPrimary: string;
   textInputSecondary: string;
   textInputAccent: string;
   navPrimary: string;
   navPrimaryActive: string;
   navPrimaryInactive: string;
   navSecondary: string;
   navSecondaryActive: string;
   navSecondaryInactive: string;
   navAccent: string;
   navActiveText: string;
   navInactiveText: string;
}

function hexToRgb(hex: string, alpha?: number): string {
   if (alpha) {
      if (alpha < 0 || alpha > 1) {
         alpha = 1;
      }
   }

   let rgbValues = parseRgbValuesFromHex(hex);

   if (alpha) {
      return 'rgba(' + [rgbValues[0], rgbValues[1], rgbValues[2], alpha].join(',') + ')';
   } else {
      return 'rgb(' + [rgbValues[0], rgbValues[1], rgbValues[2]].join(',') + ')';
   }
}

function parseRgbValuesFromHex(hex: string): [number, number, number] {
   if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      let hexSplit: string[] = hex.substring(1).split('');

      if (hexSplit.length == 3) {
         hexSplit = [hexSplit[0], hexSplit[0], hexSplit[1], hexSplit[1], hexSplit[2], hexSplit[2]];
      }

      let value = '0x' + hexSplit.join('');
      return [(parseInt(value) >> 16) & 255, (parseInt(value) >> 8) & 255, parseInt(value) & 255];
   }

   console.log('invalid hex value: ', hex);
   return [255, 255, 255];
}

// a negative percentage will make it darker
function getRgbShade(rbgValue: string, percentage: number): string {
   let i = parseInt;

   let r = Math.round;

   let [a, b, c, d] = rbgValue.split(",");

   let isNegative = percentage < 0;

   let t = isNegative ? 0 : percentage * 255 ** 2

   let changePercentage = isNegative ? 1 + percentage : 1 - percentage;

   return "rgb" + (d ? "a(" : "(") + r((changePercentage * i(a[3] == "a" ? a.slice(5) : a.slice(4)) ** 2 + t) ** 0.5) + "," + r((changePercentage * i(b) ** 2 + t) ** 0.5) + "," + r((changePercentage * i(c) ** 2 + t) ** 0.5) + (d ? "," + d : ")");
}

function getAttributeColorShades(color: 'primary' | 'secondary' | 'accent' | 'text-primary', mode: 'light' | 'dark', defaultTheme: ThemeDataAttrs, value?: string): { [key: string]: string | null } {
   return {
      [`--color-${color}-${mode}-10`]: value ? getRgbShade(hexToRgb(value), mode === 'dark' ? -0.1 : 0.1) : getRgbShade(defaultTheme.primary, 'dark' ? -0.1 : 0.1),
      [`--color-${color}-${mode}-20`]: value ? getRgbShade(hexToRgb(value), mode === 'dark' ? - 0.2 : 0.2) : getRgbShade(defaultTheme.primary, mode === 'dark' ? - 0.2 : 0.2),
      [`--color-${color}-${mode}-30`]: value ? getRgbShade(hexToRgb(value), mode === 'dark' ? - 0.3 : 0.3) : getRgbShade(defaultTheme.primary, mode === 'dark' ? - 0.3 : 0.3),
      [`--color-${color}-${mode}-40`]: value ? getRgbShade(hexToRgb(value), mode === 'dark' ? - 0.4 : 0.4) : getRgbShade(defaultTheme.primary, mode === 'dark' ? - 0.4 : 0.4),
      [`--color-${color}-${mode}-50`]: value ? getRgbShade(hexToRgb(value), mode === 'dark' ? - 0.5 : 0.5) : getRgbShade(defaultTheme.primary, mode === 'dark' ? - 0.5 : 0.5),
   }
}


const backgroundImageSet = ref(false);
export default function useAppTheme() {
   const mapTheme = (variables: Partial<ThemeDataAttrs>): { [key: string]: string | null } => {
      return {
         '--color-bg-primary': variables.backgroundColor ? variables.backgroundColor : defaultTheme.backgroundColor,
         '--background-image-custom': variables.backgroundImage ? `url("${variables.backgroundImage}")` : `url("${defaultTheme.backgroundImage}")`,
         '--color-primary': variables.primary ? variables.primary : defaultTheme.primary,
         '--color-secondary': variables.secondary ? variables.secondary : defaultTheme.secondary,
         '--color-accent': variables.accent ? variables.accent : defaultTheme.accent,
         '--color-btn-primary': variables.btnPrimary ? variables.btnPrimary : defaultTheme.btnPrimary,
         '--color-btn-secondary': variables.secondary ? variables.secondary : defaultTheme.secondary,
         '--color-btn-accent': variables.btnAccent ? variables.btnAccent : defaultTheme.btnAccent,
         '--color-btn-active': variables.btnActive ? variables.btnActive : defaultTheme.btnActive,
         '--color-btn-disabled': variables.btnDisabled ? variables.btnDisabled : defaultTheme.btnDisabled,
         '--color-btn-disabled-text': variables.btnDisabledText ? variables.btnDisabledText : defaultTheme.btnDisabledText,
         '--color-btn-text': variables.btnText ? variables.btnText : defaultTheme.btnText,
         '--color-btn-hover': variables.btnHover ? variables.btnHover : defaultTheme.btnHover,
         '--color-btn-hover-text': variables.btnHoverText ? variables.btnHoverText : defaultTheme.btnHoverText,
         '--color-text-primary': variables.textPrimary ? variables.textPrimary : defaultTheme.textPrimary,
         '--color-text-secondary': variables.textSecondary ? variables.textSecondary : defaultTheme.textSecondary,
         '--color-text-accent': variables.accent ? variables.accent : defaultTheme.textAccent,
         '--color-text-error': variables.textError ? variables.textError : defaultTheme.textError,
         '--color-text-placeholder': variables.textPlaceholder ? variables.textPlaceholder : defaultTheme.textPlaceholder,
         '--color-text-input-primary': variables.textInputPrimary ? variables.textInputPrimary : defaultTheme.textInputPrimary,
         '--color-text-input-secondary': variables.textInputSecondary ? variables.textInputSecondary : defaultTheme.textInputSecondary,
         '--color-text-input-accent': variables.textInputAccent ? variables.textInputAccent : defaultTheme.textInputAccent,
         '--color-nav-primary': variables.navPrimary ? variables.navPrimary : defaultTheme.navPrimary,
         '--color-nav-primary-active': variables.navPrimaryActive ? variables.navPrimaryActive : defaultTheme.navPrimaryActive,
         '--color-nav-primary-inactive': variables.navPrimaryInactive ? variables.navPrimaryInactive : defaultTheme.navPrimaryInactive,
         '--color-nav-secondary': variables.navSecondary ? variables.navSecondary : defaultTheme.navSecondary,
         '--color-nav-secondary-active': variables.navSecondaryActive ? variables.navSecondaryActive : defaultTheme.navSecondaryActive,
         '--color-nav-secondary-inactive': variables.navSecondaryInactive ? variables.navSecondaryInactive : defaultTheme.navSecondaryInactive,
         '--color-nav-accent': variables.navAccent ? variables.navAccent : defaultTheme.navAccent,
         '--color-nav-active-text': variables.navActiveText ? variables.navActiveText : defaultTheme.navActiveText,
         '--color-nav-inactive-text': variables.navInactiveText ? variables.navInactiveText : defaultTheme.navInactiveText,
      };
   };

   const defaultTheme: ThemeDataAttrs = {
      backgroundColor: '#F5F5F5',
      backgroundImage: '',
      primary: '#607D8B',
      secondary: '#CFD8DC',
      accent: '#FF5252',
      btnPrimary: '#00796B',
      btnSecondary: '#FFA726',
      btnAccent: '#FFA726',
      btnActive: '#2196F3',
      btnDisabled: '#9E9E9E',
      btnDisabledText: '#BDBDBD',
      btnText: '#FFFFFF',
      btnHover: '#009688',
      btnHoverText: '#FFFFFF',
      textPrimary: '#000000',
      textSecondary: '#FFFFFF',
      textAccent: '#000000',
      textError: '#FF0000',
      textPlaceholder: '#D3D3D3',
      textInputPrimary: '#000000',
      textInputSecondary: '#FFFFFF',
      textInputAccent: '#000000',
      navPrimary: '#00796B',
      navPrimaryActive: '#FFFFFF',
      navPrimaryInactive: '#CFD8DC',
      navSecondary: '#607D8B',
      navSecondaryActive: '#009688',
      navSecondaryInactive: '#607D8B',
      navAccent: '#EF5350',
      navActiveText: '#FFFFFF',
      navInactiveText: '#000000'
   }

   const themeData = reactive<ThemeDataAttrs>(defaultTheme)

   const setTheme = (data: Partial<ThemeDataAttrs>) => {
      Object.assign(themeData, JSON.parse(JSON.stringify(data)));
      applyTheme();
   }

   // TODO: add google fonts support
   const applyTheme = (): void => {
      const themeObject: { [key: string]: string | null } = mapTheme(themeData);
      if (!themeObject) return;

      const root = document.documentElement;

      Object.keys(themeObject).forEach((property) => {
         if (property === 'name') {
            return;
         }

         root.style.setProperty(property, themeObject[property]);
      });
   };

   return {
      themeData: readonly(themeData),
      setTheme,
   }
}
