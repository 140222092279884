import { ref, computed, readonly } from "vue"

import { InputStyle } from '../models'

const inputFieldStyle = ref<InputStyle>('default');
export default function useInputStyles() {
   const textInputDefault = {
      input: 'block py-2.5 px-0 w-full font-base text-xl md:text-2xl text-txt-primary bg-transparent border-0 border-b-2 border-accent appearance-none dark:text-gray-400 dark:border-gray-600 focus:outline-none peer',
      label: 'peer-focus:font-medium absolute text-xl md:text-2xl text-txt-primary dark:text-gray-400 duration-300 transform -translate-y-6 md:-translate-y-7 scale-100 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-txt-primary peer-focus:dark:text-gray-400 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-100 peer-focus:-translate-y-10 peer-placeholder-shown:text-txt-primary'
   }

   const textInputFilled = {
      input: 'block mt-2 py-2.5 pl-2 w-full font-base text-xl md:text-2xl text-txt-primary bg-accent appearance-none dark:text-gray-400 dark:border-gray-600 focus:outline-none peer',
      label: 'font-medium absolute text-xl md:text-2xl text-txt-primary dark:text-gray-400 duration-300 transform top-0 -z-10 origin-[0] left-0 scale-100 -translate-y-6 md:-translate-y-7',
   }

   const textInputFilledRounded = {
      input: 'block mt-2  py-2.5 pl-2 w-full font-base text-xl md:text-2xl text-txt-primary bg-accent rounded-2xl appearance-none dark:text-gray-400 dark:border-gray-600 focus:outline-none peer',
      label: 'font-medium absolute text-xl md:text-2xl text-txt-primary dark:text-gray-400 duration-300 transform top-0 -z-10 origin-[0] left-0 scale-100 -translate-y-6 md:-translate-y-7',
   }

   const textInputOutline = {
      input: 'block mt-2 py-2.5 pl-2 w-full font-base text-xl md:text-2xl text-txt-primary bg-transparent border border-accent appearance-none dark:text-gray-400 dark:border-gray-600 focus:outline-none peer',
      label: 'font-medium absolute text-xl md:text-2xl text-txt-primary dark:text-gray-400 duration-300 transform top-0 -z-10 origin-[0] left-0 scale-100 -translate-y-9 md:-translate-y-10',
   }

   const textInputOutlineRounded = {
      input: 'block mt-2 py-2.5 pl-2 w-full font-base text-xl md:text-2xl text-txt-primary bg-transparent border border-accent rounded-2xl appearance-none dark:text-gray-400 dark:border-gray-600 focus:outline-none peer',
      label: 'font-medium absolute text-xl md:text-2xl text-txt-primary dark:text-gray-400 duration-300 transform top-0 -z-10 origin-[0] left-0 scale-100 -translate-y-9 md:-translate-y-10',
   }

   const fileUploadDefault = {
      input: 'block py-2.5 px-0 w-full font-base text-xl md:text-2xl text-txt-primary bg-transparent border-0 border-b-2 border-accent appearance-none dark:text-gray-400 dark:border-gray-600 focus:outline-none peer',
      label: 'peer-focus:font-medium relative text-xl md:text-2xl text-txt-primary dark:text-gray-400 duration-300 transform -translate-y-6 md:-translate-y-7 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-txt-primary peer-focus:dark:text-gray-400 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 peer-placeholder-shown:text-txt-primary'
   }
   const dropdownCustomDefault = {
      input: 'block py-2.5 px-0 w-full font-base text-xl md:text-2xl text-txt-primary bg-transparent border-0 border-b-2 border-accent appearance-none dark:text-gray-400 dark:border-gray-600 focus:outline-none peer',
      label: 'peer-focus:font-medium text-xl md:text-2xl text-txt-primary dark:text-gray-400 duration-300 transform -translate-y-6 md:-translate-y-7 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-txt-primary peer-focus:dark:text-gray-400 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 peer-placeholder-shown:text-txt-primary',
      classes:{
          container: 'relative mx-auto w-full flex items-center justify-end box-border cursor-pointer border-accent border-0 border-b-2 text-base leading-snug outline-none',
          containerDisabled: 'cursor-default bg-gray-100',
          containerOpen: 'rounded-b-none',
          containerOpenTop: 'rounded-t-none',
          containerActive: '',
          wrapper: 'relative mx-auto w-full flex items-center justify-end box-border cursor-pointer outline-none pb-2',
          singleLabel: 'text-txt-primary text-xl flex items-center h-full max-w-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3.5 pr-16 box-border rtl:left-auto rtl:right-0 rtl:pl-0 rtl:pr-3.5',
          singleLabelText: 'overflow-ellipsis overflow-hidden block whitespace-nowrap max-w-full',
          multipleLabel: 'text-txt-primary text-xl flex items-center h-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-2 rtl:left-auto rtl:right-0 rtl:pl-0 rtl:pr-2',
          search: 'w-full absolute inset-0 outline-none focus:ring-0 appearance-none box-border border-0 text-base font-sans bg-white rounded pl-2 rtl:pl-0 rtl:pr-2',
          tags: 'flex-grow flex-shrink flex flex-wrap items-center mt-1 pl-2 min-w-0 rtl:pl-0 rtl:pr-2',
          tag: 'bg-green-500 text-white text-sm font-semibold py-0.5 pl-2 rounded mr-1 mb-1 flex items-center whitespace-nowrap min-w-0 rtl:pl-0 rtl:pr-2 rtl:mr-0 rtl:ml-1',
          tagDisabled: 'pr-2 opacity-50 rtl:pl-2',
          tagWrapper: 'whitespace-nowrap overflow-hidden overflow-ellipsis',
          tagWrapperBreak: 'whitespace-normal break-all',
          tagRemove: 'flex items-center justify-center p-1 mx-0.5 rounded-sm hover:bg-black hover:bg-opacity-10 group',
          tagRemoveIcon: 'bg-multiselect-remove bg-center bg-no-repeat opacity-30 inline-block w-3 h-3 group-hover:opacity-60',
          tagsSearchWrapper: 'inline-block relative mx-1 mb-1 flex-grow flex-shrink h-full',
          tagsSearch: 'absolute inset-0 border-0 outline-none focus:ring-0 appearance-none p-0 text-base font-sans box-border w-full',
          tagsSearchCopy: 'invisible whitespace-pre-wrap inline-block h-px',
          placeholder: 'flex items-center h-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3.5 text-gray-400 rtl:left-auto rtl:right-0 rtl:pl-0 rtl:pr-3.5',
          caret: 'bg-multiselect-caret bg-center bg-no-repeat w-2.5 h-4 py-px box-content mr-3.5 relative z-10 opacity-40 flex-shrink-0 flex-grow-0 transition-transform transform pointer-events-none rtl:mr-0 rtl:ml-3.5',
          caretOpen: 'rotate-180 pointer-events-auto',
          clear: 'pr-3.5 relative z-10 opacity-40 transition duration-300 flex-shrink-0 flex-grow-0 flex hover:opacity-80 rtl:pr-0 rtl:pl-3.5',
          clearIcon: 'bg-multiselect-remove bg-center bg-no-repeat w-2.5 h-4 py-px box-content inline-block',
          spinner: 'bg-multiselect-spinner bg-center bg-no-repeat w-4 h-4 z-10 mr-3.5 animate-spin flex-shrink-0 flex-grow-0 rtl:mr-0 rtl:ml-3.5',
          infinite: 'flex items-center justify-center w-full',
          infiniteSpinner: 'bg-multiselect-spinner bg-center bg-no-repeat w-4 h-4 z-10 animate-spin flex-shrink-0 flex-grow-0 m-3.5',
          dropdown: 'max-h-60 absolute -left-px -right-px bottom-0 transform translate-y-full border border-accent -mt-px overflow-y-scroll z-50 bg-accent flex flex-col',
          dropdownTop: '-translate-y-full top-px bottom-auto rounded-b-none rounded-t',
          dropdownHidden: 'hidden',
          options: 'flex flex-col p-0 m-0 list-none',
          optionsTop: '',
          group: 'p-0 m-0',
          groupLabel: 'flex text-sm box-border items-center justify-start text-left py-1 px-3 font-semibold bg-gray-200 cursor-default leading-normal',
          groupLabelPointable: 'cursor-pointer',
          groupLabelPointed: 'bg-gray-300 text-gray-700',
          groupLabelSelected: 'bg-green-600 text-white',
          groupLabelDisabled: 'bg-gray-100 text-gray-300 cursor-not-allowed',
          groupLabelSelectedPointed: 'bg-green-600 text-white opacity-90',
          groupLabelSelectedDisabled: 'text-green-100 bg-green-600 bg-opacity-50 cursor-not-allowed',
          groupOptions: 'p-0 m-0',
          option: 'flex items-center justify-start box-border text-left cursor-pointer text-base leading-snug py-2 px-3',
          optionPointed: 'text-gray-800 bg-accent opacity-90',
          optionSelected: 'text-white bg-primary opacity-80',
          optionDisabled: 'text-gray-300 cursor-not-allowed',
          optionSelectedPointed: 'text-white bg-primary opacity-90',
          optionSelectedDisabled: 'text-white bg-primary opacity-30 bg-opacity-50 cursor-not-allowed',
          noOptions: 'py-2 px-3 text-gray-600 bg-white text-left rtl:text-right',
          noResults: 'py-2 px-3 text-gray-600 bg-white text-left rtl:text-right',
          fakeInput: 'bg-transparent absolute left-0 right-0 -bottom-px w-full h-px border-0 p-0 appearance-none outline-none text-transparent',
          assist: 'absolute -m-px w-px h-px overflow-hidden',
          spacer: 'h-9 py-px box-content'
        }
   }
   const dropdownCustomFilled = {
      input: 'block py-2.5 px-0 w-full font-base text-xl md:text-2xl text-txt-primary bg-accent border-2 border-accent appearance-none dark:text-gray-400 dark:border-gray-600 focus:outline-none peer',
      label: 'peer-focus:font-medium text-xl md:text-2xl text-txt-primary dark:text-gray-400 duration-300 transform -translate-y-6 md:-translate-y-7 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-txt-primary peer-focus:dark:text-gray-400 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 peer-placeholder-shown:text-txt-primary',
      classes:{
          container: 'relative mx-auto w-full flex items-center justify-end box-border cursor-pointer border-accent border text-base leading-snug outline-none bg-accent',
          containerDisabled: 'cursor-default bg-gray-100',
          containerOpen: 'rounded-b-none',
          containerOpenTop: 'rounded-t-none',
          containerActive: 'relative mx-auto w-full flex items-center justify-end box-border cursor-pointer border-accent border text-base leading-snug outline-none bg-accent',
          wrapper: 'relative mx-auto w-full flex items-center justify-end box-border cursor-pointer outline-none py-4',
          singleLabel: 'flex items-center h-full max-w-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3.5 pr-16 box-border rtl:left-auto rtl:right-0 rtl:pl-0 rtl:pr-3.5 text-txt-primary text-xl md:text-2xl ',
          singleLabelText: 'overflow-ellipsis overflow-hidden block whitespace-nowrap max-w-full',
          multipleLabel: 'text-txt-primary text-xl md:text-2xl flex items-center h-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-2 rtl:left-auto rtl:right-0 rtl:pl-0 rtl:pr-2',
          search: 'w-full absolute inset-0 outline-none focus:ring-0 appearance-none box-border border-0 text-base font-sans bg-white rounded pl-2 rtl:pl-0 rtl:pr-2',
          tags: 'flex-grow flex-shrink flex flex-wrap items-center mt-1 pl-2 min-w-0 rtl:pl-0 rtl:pr-2',
          tag: 'bg-green-500 text-white text-sm font-semibold py-0.5 pl-2 rounded mr-1 mb-1 flex items-center whitespace-nowrap min-w-0 rtl:pl-0 rtl:pr-2 rtl:mr-0 rtl:ml-1',
          tagDisabled: 'pr-2 opacity-50 rtl:pl-2',
          tagWrapper: 'whitespace-nowrap overflow-hidden overflow-ellipsis',
          tagWrapperBreak: 'whitespace-normal break-all',
          tagRemove: 'flex items-center justify-center p-1 mx-0.5 rounded-sm hover:bg-black hover:bg-opacity-10 group',
          tagRemoveIcon: 'bg-multiselect-remove bg-center bg-no-repeat opacity-30 inline-block w-3 h-3 group-hover:opacity-60',
          tagsSearchWrapper: 'inline-block relative mx-1 mb-1 flex-grow flex-shrink h-full',
          tagsSearch: 'absolute inset-0 border-0 outline-none focus:ring-0 appearance-none p-0 text-base font-sans box-border w-full',
          tagsSearchCopy: 'invisible whitespace-pre-wrap inline-block h-px',
          placeholder: 'flex items-center h-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3.5 text-gray-400 rtl:left-auto rtl:right-0 rtl:pl-0 rtl:pr-3.5',
          // caret: 'bg-multiselect-caret bg-center bg-no-repeat w-2.5 h-4 py-px box-content mr-3.5 relative z-10 opacity-40 flex-shrink-0 flex-grow-0 transition-transform transform pointer-events-none rtl:mr-0 rtl:ml-3.5',
          // caretOpen: 'rotate-180 pointer-events-auto',
          clear: 'pr-3.5 relative z-10 opacity-40 transition duration-300 flex-shrink-0 flex-grow-0 flex hover:opacity-80 rtl:pr-0 rtl:pl-3.5',
          clearIcon: 'bg-multiselect-remove bg-center bg-no-repeat w-2.5 h-4 py-px box-content inline-block',
          spinner: 'bg-multiselect-spinner bg-center bg-no-repeat w-4 h-4 z-10 mr-3.5 animate-spin flex-shrink-0 flex-grow-0 rtl:mr-0 rtl:ml-3.5',
          infinite: 'flex items-center justify-center w-full',
          infiniteSpinner: 'bg-multiselect-spinner bg-center bg-no-repeat w-4 h-4 z-10 animate-spin flex-shrink-0 flex-grow-0 m-3.5',
          dropdown: 'max-h-60 absolute -left-px -right-px bottom-0 transform translate-y-full border border-accent -mt-px overflow-y-scroll z-50 bg-accent flex flex-col rounded-b',
          dropdownTop: '-translate-y-full top-px bottom-auto rounded-b-none rounded-t-none',
          dropdownHidden: 'hidden',
          options: 'flex flex-col p-0 m-0 list-none',
          optionsTop: '',
          group: 'p-0 m-0',
          groupLabel: 'flex text-sm box-border items-center justify-start text-left py-1 px-3 font-semibold bg-gray-200 cursor-default leading-normal',
          groupLabelPointable: 'cursor-pointer',
          groupLabelPointed: 'bg-gray-300 text-gray-700',
          groupLabelSelected: 'bg-green-600 text-white',
          groupLabelDisabled: 'bg-gray-100 text-gray-300 cursor-not-allowed',
          groupLabelSelectedPointed: 'bg-green-600 text-white opacity-90',
          groupLabelSelectedDisabled: 'text-green-100 bg-green-600 bg-opacity-50 cursor-not-allowed',
          groupOptions: 'p-0 m-0',
          option: 'flex items-center justify-start box-border text-left cursor-pointer text-base leading-snug py-2 px-3',
          optionPointed: 'text-gray-800 bg-accent opacity-90',
          optionSelected: 'text-white bg-primary opacity-80',
          optionDisabled: 'text-gray-300 cursor-not-allowed',
          optionSelectedPointed: 'text-white bg-primary opacity-90',
          optionSelectedDisabled: 'text-white bg-primary opacity-30 bg-opacity-50 cursor-not-allowed',
          noOptions: 'py-2 px-3 text-gray-600 bg-white text-left rtl:text-right',
          noResults: 'py-2 px-3 text-gray-600 bg-white text-left rtl:text-right',
          fakeInput: 'bg-transparent absolute left-0 right-0 -bottom-px w-full h-px border-0 p-0 appearance-none outline-none text-transparent',
          assist: 'absolute -m-px w-px h-px overflow-hidden',
          spacer: 'h-9 py-px box-content'
        }
   }
   const dropdownCustomFilledRounded = {
      input: 'block py-2.5 px-0 w-full font-base text-xl md:text-2xl text-txt-primary bg-accent border-2 border-accent appearance-none dark:text-gray-400 dark:border-gray-600 focus:outline-none peer',
      label: 'peer-focus:font-medium text-xl md:text-2xl text-txt-primary dark:text-gray-400 duration-300 transform -translate-y-6 md:-translate-y-7 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-txt-primary peer-focus:dark:text-gray-400 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 peer-placeholder-shown:text-txt-primary',
      classes:{
          container: 'relative mx-auto w-full flex items-center justify-end box-border cursor-pointer border-accent border text-base leading-snug outline-none rounded-2xl bg-accent',
          containerDisabled: 'cursor-default bg-gray-100',
          containerOpen: 'rounded-b-none',
          containerOpenTop: 'rounded-t-2xl',
          containerActive: 'relative mx-auto w-full flex items-center justify-end box-border cursor-pointer border-accent border text-base leading-snug outline-none rounded-2xl bg-accent',
          wrapper: 'relative mx-auto w-full flex items-center justify-end box-border cursor-pointer outline-none py-4',
          singleLabel: 'text-txt-primary text-xl md:text-2xl flex items-center h-full max-w-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3.5 pr-16 box-border rtl:left-auto rtl:right-0 rtl:pl-0 rtl:pr-3.5',
          singleLabelText: 'overflow-ellipsis overflow-hidden block whitespace-nowrap max-w-full',
          multipleLabel: 'text-txt-primary text-xl md:text-2xl flex items-center h-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-2 rtl:left-auto rtl:right-0 rtl:pl-0 rtl:pr-2',
          search: 'w-full absolute inset-0 outline-none focus:ring-0 appearance-none box-border border-0 text-base font-sans bg-white rounded pl-2 rtl:pl-0 rtl:pr-2',
          tags: 'flex-grow flex-shrink flex flex-wrap items-center mt-1 pl-2 min-w-0 rtl:pl-0 rtl:pr-2',
          tag: 'bg-green-500 text-white text-sm font-semibold py-0.5 pl-2 rounded mr-1 mb-1 flex items-center whitespace-nowrap min-w-0 rtl:pl-0 rtl:pr-2 rtl:mr-0 rtl:ml-1',
          tagDisabled: 'pr-2 opacity-50 rtl:pl-2',
          tagWrapper: 'whitespace-nowrap overflow-hidden overflow-ellipsis',
          tagWrapperBreak: 'whitespace-normal break-all',
          tagRemove: 'flex items-center justify-center p-1 mx-0.5 rounded-sm hover:bg-black hover:bg-opacity-10 group',
          tagRemoveIcon: 'bg-multiselect-remove bg-center bg-no-repeat opacity-30 inline-block w-3 h-3 group-hover:opacity-60',
          tagsSearchWrapper: 'inline-block relative mx-1 mb-1 flex-grow flex-shrink h-full',
          tagsSearch: 'absolute inset-0 border-0 outline-none focus:ring-0 appearance-none p-0 text-base font-sans box-border w-full',
          tagsSearchCopy: 'invisible whitespace-pre-wrap inline-block h-px',
          placeholder: 'flex items-center h-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3.5 text-gray-400 rtl:left-auto rtl:right-0 rtl:pl-0 rtl:pr-3.5',
          // caret: 'bg-multiselect-caret bg-center bg-no-repeat w-2.5 h-4 py-px box-content mr-3.5 relative z-10 opacity-40 flex-shrink-0 flex-grow-0 transition-transform transform pointer-events-none rtl:mr-0 rtl:ml-3.5',
          // caretOpen: 'rotate-180 pointer-events-auto',
          clear: 'pr-3.5 relative z-10 opacity-40 transition duration-300 flex-shrink-0 flex-grow-0 flex hover:opacity-80 rtl:pr-0 rtl:pl-3.5',
          clearIcon: 'bg-multiselect-remove bg-center bg-no-repeat w-2.5 h-4 py-px box-content inline-block',
          spinner: 'bg-multiselect-spinner bg-center bg-no-repeat w-4 h-4 z-10 mr-3.5 animate-spin flex-shrink-0 flex-grow-0 rtl:mr-0 rtl:ml-3.5',
          infinite: 'flex items-center justify-center w-full',
          infiniteSpinner: 'bg-multiselect-spinner bg-center bg-no-repeat w-4 h-4 z-10 animate-spin flex-shrink-0 flex-grow-0 m-3.5',
          dropdown: 'max-h-60 absolute -left-px -right-px bottom-0 transform translate-y-full border border-accent -mt-px overflow-y-scroll z-50 bg-accent flex flex-col rounded-b',
          dropdownTop: '-translate-y-full top-px bottom-auto rounded-b-none rounded-t',
          dropdownHidden: 'hidden',
          options: 'flex flex-col p-0 m-0 list-none',
          optionsTop: '',
          group: 'p-0 m-0',
          groupLabel: 'flex text-sm box-border items-center justify-start text-left py-1 px-3 font-semibold bg-gray-200 cursor-default leading-normal',
          groupLabelPointable: 'cursor-pointer',
          groupLabelPointed: 'bg-gray-300 text-gray-700',
          groupLabelSelected: 'bg-green-600 text-white',
          groupLabelDisabled: 'bg-gray-100 text-gray-300 cursor-not-allowed',
          groupLabelSelectedPointed: 'bg-green-600 text-white opacity-90',
          groupLabelSelectedDisabled: 'text-green-100 bg-green-600 bg-opacity-50 cursor-not-allowed',
          groupOptions: 'p-0 m-0',
          option: 'flex items-center justify-start box-border text-left cursor-pointer text-base leading-snug py-2 px-3',
          optionPointed: 'text-gray-800 bg-accent opacity-90',
          optionSelected: 'text-white bg-primary opacity-80',
          optionDisabled: 'text-gray-300 cursor-not-allowed',
          optionSelectedPointed: 'text-white bg-primary opacity-90',
          optionSelectedDisabled: 'text-white bg-primary opacity-30 bg-opacity-50 cursor-not-allowed',
          noOptions: 'py-2 px-3 text-gray-600 bg-white text-left rtl:text-right',
          noResults: 'py-2 px-3 text-gray-600 bg-white text-left rtl:text-right',
          fakeInput: 'bg-transparent absolute left-0 right-0 -bottom-px w-full h-px border-0 p-0 appearance-none outline-none text-transparent',
          assist: 'absolute -m-px w-px h-px overflow-hidden',
          spacer: 'h-9 py-px box-content'
        }
   }
   const dropdownCustomOutline = {
      input: 'block py-2.5 px-0 w-full font-base text-xl md:text-2xl text-txt-primary bg-transparent border-2 border-accent appearance-none dark:text-gray-400 dark:border-gray-600 focus:outline-none peer',
      label: 'peer-focus:font-medium text-xl md:text-2xl text-txt-primary dark:text-gray-400 duration-300 transform -translate-y-6 md:-translate-y-7 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-txt-primary peer-focus:dark:text-gray-400 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 peer-placeholder-shown:text-txt-primary',
      classes:{
          container: 'relative mx-auto w-full flex items-center justify-end box-border cursor-pointer border-accent border text-base leading-snug outline-none',
          containerDisabled: 'cursor-default bg-gray-100',
          containerOpen: 'rounded-b-none',
          containerOpenTop: 'rounded-t-none',
          containerActive: 'relative mx-auto w-full flex items-center justify-end box-border cursor-pointer border-accent border text-base leading-snug outline-none',
          wrapper: 'relative mx-auto w-full flex items-center justify-end box-border cursor-pointer outline-none py-4',
          singleLabel: 'text-txt-primary text-xl md:text-2xl flex items-center h-full max-w-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3.5 pr-16 box-border rtl:left-auto rtl:right-0 rtl:pl-0 rtl:pr-3.5',
          singleLabelText: 'overflow-ellipsis overflow-hidden block whitespace-nowrap max-w-full',
          multipleLabel: 'text-txt-primary text-xl md:text-2xl flex items-center h-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-2 rtl:left-auto rtl:right-0 rtl:pl-0 rtl:pr-2',
          search: 'w-full absolute inset-0 outline-none focus:ring-0 appearance-none box-border border-0 text-base font-sans bg-white rounded pl-2 rtl:pl-0 rtl:pr-2',
          tags: 'flex-grow flex-shrink flex flex-wrap items-center mt-1 pl-2 min-w-0 rtl:pl-0 rtl:pr-2',
          tag: 'bg-green-500 text-white text-sm font-semibold py-0.5 pl-2 rounded mr-1 mb-1 flex items-center whitespace-nowrap min-w-0 rtl:pl-0 rtl:pr-2 rtl:mr-0 rtl:ml-1',
          tagDisabled: 'pr-2 opacity-50 rtl:pl-2',
          tagWrapper: 'whitespace-nowrap overflow-hidden overflow-ellipsis',
          tagWrapperBreak: 'whitespace-normal break-all',
          tagRemove: 'flex items-center justify-center p-1 mx-0.5 rounded-sm hover:bg-black hover:bg-opacity-10 group',
          tagRemoveIcon: 'bg-multiselect-remove bg-center bg-no-repeat opacity-30 inline-block w-3 h-3 group-hover:opacity-60',
          tagsSearchWrapper: 'inline-block relative mx-1 mb-1 flex-grow flex-shrink h-full',
          tagsSearch: 'absolute inset-0 border-0 outline-none focus:ring-0 appearance-none p-0 text-base font-sans box-border w-full',
          tagsSearchCopy: 'invisible whitespace-pre-wrap inline-block h-px',
          placeholder: 'flex items-center h-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3.5 text-gray-400 rtl:left-auto rtl:right-0 rtl:pl-0 rtl:pr-3.5',
          // caret: 'bg-multiselect-caret bg-center bg-no-repeat w-2.5 h-4 py-px box-content mr-3.5 relative z-10 opacity-40 flex-shrink-0 flex-grow-0 transition-transform transform pointer-events-none rtl:mr-0 rtl:ml-3.5',
          // caretOpen: 'rotate-180 pointer-events-auto',
          clear: 'pr-3.5 relative z-10 opacity-40 transition duration-300 flex-shrink-0 flex-grow-0 flex hover:opacity-80 rtl:pr-0 rtl:pl-3.5',
          clearIcon: 'bg-multiselect-remove bg-center bg-no-repeat w-2.5 h-4 py-px box-content inline-block',
          spinner: 'bg-multiselect-spinner bg-center bg-no-repeat w-4 h-4 z-10 mr-3.5 animate-spin flex-shrink-0 flex-grow-0 rtl:mr-0 rtl:ml-3.5',
          infinite: 'flex items-center justify-center w-full',
          infiniteSpinner: 'bg-multiselect-spinner bg-center bg-no-repeat w-4 h-4 z-10 animate-spin flex-shrink-0 flex-grow-0 m-3.5',
          dropdown: 'max-h-60 absolute -left-px -right-px bottom-0 transform translate-y-full border border-accent -mt-px overflow-y-scroll z-50 bg-accent flex flex-col rounded-b',
          dropdownTop: '-translate-y-full top-px bottom-auto rounded-b-none rounded-t-none',
          dropdownHidden: 'hidden',
          options: 'flex flex-col p-0 m-0 list-none',
          optionsTop: '',
          group: 'p-0 m-0',
          groupLabel: 'flex text-sm box-border items-center justify-start text-left py-1 px-3 font-semibold bg-gray-200 cursor-default leading-normal',
          groupLabelPointable: 'cursor-pointer',
          groupLabelPointed: 'bg-gray-300 text-gray-700',
          groupLabelSelected: 'bg-green-600 text-white',
          groupLabelDisabled: 'bg-gray-100 text-gray-300 cursor-not-allowed',
          groupLabelSelectedPointed: 'bg-green-600 text-white opacity-90',
          groupLabelSelectedDisabled: 'text-green-100 bg-green-600 bg-opacity-50 cursor-not-allowed',
          groupOptions: 'p-0 m-0',
          option: 'flex items-center justify-start box-border text-left cursor-pointer text-base leading-snug py-2 px-3',
          optionPointed: 'text-gray-800 bg-accent opacity-90',
          optionSelected: 'text-white bg-primary opacity-80',
          optionDisabled: 'text-gray-300 cursor-not-allowed',
          optionSelectedPointed: 'text-white bg-primary opacity-90',
          optionSelectedDisabled: 'text-white bg-primary opacity-30 bg-opacity-50 cursor-not-allowed',
          noOptions: 'py-2 px-3 text-gray-600 bg-white text-left rtl:text-right',
          noResults: 'py-2 px-3 text-gray-600 bg-white text-left rtl:text-right',
          fakeInput: 'bg-transparent absolute left-0 right-0 -bottom-px w-full h-px border-0 p-0 appearance-none outline-none text-transparent',
          assist: 'absolute -m-px w-px h-px overflow-hidden',
          spacer: 'h-9 py-px box-content'
        }
   }
   const dropdownCustomOutlineRounded = {
      input: 'block py-2.5 px-0 w-full font-base text-xl md:text-2xl text-txt-primary bg-transparent border-2 border-accent appearance-none dark:text-gray-400 dark:border-gray-600 focus:outline-none peer',
      label: 'peer-focus:font-medium text-xl md:text-2xl text-txt-primary dark:text-gray-400 duration-300 transform -translate-y-6 md:-translate-y-7 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-txt-primary peer-focus:dark:text-gray-400 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 peer-placeholder-shown:text-txt-primary',
      classes:{
          container: 'relative mx-auto w-full flex items-center justify-end box-border cursor-pointer border-accent border text-base leading-snug outline-none rounded-2xl',
          containerDisabled: 'cursor-default bg-gray-100',
          containerOpen: 'rounded-b-none',
          containerOpenTop: 'rounded-t-2xl',
          containerActive: 'relative mx-auto w-full flex items-center justify-end box-border cursor-pointer border-accent border text-base leading-snug outline-none rounded-2xl',
          wrapper: 'relative mx-auto w-full flex items-center justify-end box-border cursor-pointer outline-none py-4',
          singleLabel: 'text-txt-primary text-xl md:text-2xl flex items-center h-full max-w-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3.5 pr-16 box-border rtl:left-auto rtl:right-0 rtl:pl-0 rtl:pr-3.5',
          singleLabelText: 'overflow-ellipsis overflow-hidden block whitespace-nowrap max-w-full',
          multipleLabel: 'text-txt-primary text-xl md:text-2xl flex items-center h-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-2 rtl:left-auto rtl:right-0 rtl:pl-0 rtl:pr-2',
          search: 'w-full absolute inset-0 outline-none focus:ring-0 appearance-none box-border border-0 text-base font-sans bg-white rounded pl-2 rtl:pl-0 rtl:pr-2',
          tags: 'flex-grow flex-shrink flex flex-wrap items-center mt-1 pl-2 min-w-0 rtl:pl-0 rtl:pr-2',
          tag: 'bg-green-500 text-white text-sm font-semibold py-0.5 pl-2 rounded mr-1 mb-1 flex items-center whitespace-nowrap min-w-0 rtl:pl-0 rtl:pr-2 rtl:mr-0 rtl:ml-1',
          tagDisabled: 'pr-2 opacity-50 rtl:pl-2',
          tagWrapper: 'whitespace-nowrap overflow-hidden overflow-ellipsis',
          tagWrapperBreak: 'whitespace-normal break-all',
          tagRemove: 'flex items-center justify-center p-1 mx-0.5 rounded-sm hover:bg-black hover:bg-opacity-10 group',
          tagRemoveIcon: 'bg-multiselect-remove bg-center bg-no-repeat opacity-30 inline-block w-3 h-3 group-hover:opacity-60',
          tagsSearchWrapper: 'inline-block relative mx-1 mb-1 flex-grow flex-shrink h-full',
          tagsSearch: 'absolute inset-0 border-0 outline-none focus:ring-0 appearance-none p-0 text-base font-sans box-border w-full',
          tagsSearchCopy: 'invisible whitespace-pre-wrap inline-block h-px',
          placeholder: 'flex items-center h-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3.5 text-gray-400 rtl:left-auto rtl:right-0 rtl:pl-0 rtl:pr-3.5',
          // caret: 'bg-multiselect-caret bg-center bg-no-repeat w-2.5 h-4 py-px box-content mr-3.5 relative z-10 opacity-40 flex-shrink-0 flex-grow-0 transition-transform transform pointer-events-none rtl:mr-0 rtl:ml-3.5',
          // caretOpen: 'rotate-180 pointer-events-auto',
          clear: 'pr-3.5 relative z-10 opacity-40 transition duration-300 flex-shrink-0 flex-grow-0 flex hover:opacity-80 rtl:pr-0 rtl:pl-3.5',
          clearIcon: 'bg-multiselect-remove bg-center bg-no-repeat w-2.5 h-4 py-px box-content inline-block',
          spinner: 'bg-multiselect-spinner bg-center bg-no-repeat w-4 h-4 z-10 mr-3.5 animate-spin flex-shrink-0 flex-grow-0 rtl:mr-0 rtl:ml-3.5',
          infinite: 'flex items-center justify-center w-full',
          infiniteSpinner: 'bg-multiselect-spinner bg-center bg-no-repeat w-4 h-4 z-10 animate-spin flex-shrink-0 flex-grow-0 m-3.5',
          dropdown: 'max-h-60 absolute -left-px -right-px bottom-0 transform translate-y-full border border-accent -mt-px overflow-y-scroll z-50 bg-accent flex flex-col rounded-b',
          dropdownTop: '-translate-y-full top-px bottom-auto rounded-b-none rounded-t',
          dropdownHidden: 'hidden',
          options: 'flex flex-col p-0 m-0 list-none',
          optionsTop: '',
          group: 'p-0 m-0',
          groupLabel: 'flex text-sm box-border items-center justify-start text-left py-1 px-3 font-semibold bg-gray-200 cursor-default leading-normal',
          groupLabelPointable: 'cursor-pointer',
          groupLabelPointed: 'bg-gray-300 text-gray-700',
          groupLabelSelected: 'bg-green-600 text-white',
          groupLabelDisabled: 'bg-gray-100 text-gray-300 cursor-not-allowed',
          groupLabelSelectedPointed: 'bg-green-600 text-white opacity-90',
          groupLabelSelectedDisabled: 'text-green-100 bg-green-600 bg-opacity-50 cursor-not-allowed',
          groupOptions: 'p-0 m-0',
          option: 'flex items-center justify-start box-border text-left cursor-pointer text-base leading-snug py-2 px-3',
          optionPointed: 'text-gray-800 bg-accent opacity-90',
          optionSelected: 'text-white bg-primary opacity-80',
          optionDisabled: 'text-gray-300 cursor-not-allowed',
          optionSelectedPointed: 'text-white bg-primary opacity-90',
          optionSelectedDisabled: 'text-white bg-primary opacity-30 bg-opacity-50 cursor-not-allowed',
          noOptions: 'py-2 px-3 text-gray-600 bg-white text-left rtl:text-right',
          noResults: 'py-2 px-3 text-gray-600 bg-white text-left rtl:text-right',
          fakeInput: 'bg-transparent absolute left-0 right-0 -bottom-px w-full h-px border-0 p-0 appearance-none outline-none text-transparent',
          assist: 'absolute -m-px w-px h-px overflow-hidden',
          spacer: 'h-9 py-px box-content'
        }
   }

   const fileUploadFilled = {
      input: 'block mt-2 py-2.5 pl-2 w-full font-base text-xl md:text-2xl text-txt-primary bg-accent appearance-none dark:text-gray-400 dark:border-gray-600 focus:outline-none peer',
      label: 'font-medium relative text-xl md:text-2xl text-txt-primary dark:text-gray-400 duration-300 transform top-0 -z-10 origin-[0] left-0 scale-75 -translate-y-6 md:-translate-y-7',
   }

   const fileUploadFilledRounded = {
      input: 'block mt-2  py-2.5 pl-2 w-full font-base text-xl md:text-2xl text-txt-primary bg-accent rounded-2xl appearance-none dark:text-gray-400 dark:border-gray-600 focus:outline-none peer',
      label: 'font-medium relative text-xl md:text-2xl text-txt-primary dark:text-gray-400 duration-300 transform top-0 -z-10 origin-[0] left-0 scale-75 -translate-y-6 md:-translate-y-7',
   }

   const fileUploadOutline = {
      input: 'block mt-2 py-2.5 pl-2 w-full font-base text-xl md:text-2xl text-txt-primary bg-transparent border border-accent appearance-none dark:text-gray-400 dark:border-gray-600 focus:outline-none peer',
      label: 'font-medium relative text-xl md:text-2xl text-txt-primary dark:text-gray-400 duration-300 transform top-0 -z-10 origin-[0] left-0 scale-75 -translate-y-6 md:-translate-y-7',
   }

   const fileUploadOutlineRounded = {
      input: 'block mt-2 py-2.5 pl-2 w-full font-base text-xl md:text-2xl text-txt-primary bg-transparent border border-accent rounded-2xl appearance-none dark:text-gray-400 dark:border-gray-600 focus:outline-none peer',
      label: 'font-medium relative text-xl md:text-2xl text-txt-primary dark:text-gray-400 duration-300 transform top-0 -z-10 origin-[0] left-0 scale-75 -translate-y-6 md:-translate-y-7',
   }

   const setInputStyle = (value: InputStyle) => {
      inputFieldStyle.value = value;
   }

   const checkboxStyle = computed((): { box: string, check: string } => {
      let style = `w-6 h-6 flex flex-shrink-0 justify-center items-center mr-2 border cursor-pointer`;

      let checkStyle = `hidden w-3 h-3 font-bold pointer-events-none`;

      switch (inputFieldStyle.value) {
         case 'default':
            checkStyle += ` text-btn-text`;
            style += ` bg-accent border-accent rounded`;
            break;
         case 'filled':
            checkStyle += ` text-txt-primary`;
            style += ` bg-primary border-primary`;
            break;
         case 'filled-rounded':
            checkStyle += ` text-txt-primary`;
            style += ` bg-primary border-primary rounded-full`;
            break;
         case 'outline':
            checkStyle += ` text-txt-primary`;
            style += ` bg-transparent border-primary`;
            break;
         case 'outline-rounded':
            checkStyle += ` text-accent`;
            style += ` bg-transparent border-accent rounded-full`;
            break;
         default:
            break;
      }

      return {
         box: style,
         check: checkStyle,
      };
   })

   const btnStyle = computed((): { style: string } => {
      let disabledStyle = `disabled:bg-btn-primary disabled:brightness-50 disabled:text-btn-text disabled:dark:bg-gray-900 disabled:dark:text-gray-800 disabled:cursor-not-allowed disabled:hover:scale-100`;

      let baseStyle = `w-full inline-block px-6 py-2.5 font-medium text-xs leading-tight uppercase focus:outline-none transition-all duration-150 ease-in`

      switch (inputFieldStyle.value) {
         case 'default':
            baseStyle += ` bg-btn-primary text-btn-text hover:bg-btn-hover hover:text-btn-hover-text hover:scale-95 rounded`;
            break;
         case 'filled':
            baseStyle += ` bg-btn-primary text-btn-text hover:bg-btn-hover hover:text-btn-hover-text hover:scale-95 rounded`;
            break;
         case 'filled-rounded':
            baseStyle += ` bg-btn-primary text-btn-text hover:bg-btn-hover hover:text-btn-hover-text hover:scale-95 rounded-2xl`;
            break;
         case 'outline':
            baseStyle += ` bg-btn-primary text-btn-text hover:bg-btn-hover hover:text-btn-hover-text hover:scale-95 rounded`;
            // baseStyle += ` border bg-transparent border-btn-primary text-btn-primary hover:bg-btn-hover hover:border-btn-hover hover:text-btn-hover-text hover:scale-95`;
            // disabledStyle = `disabled:border-btn-disabled disabled:text-btn-disabled-text disabled:dark:bg-gray-900 disabled:dark:text-gray-800 disabled:cursor-not-allowed disabled:hover:scale-100 disabled:hover:scale-100 disabled:hover:bg-transparent`;
            break;
         case 'outline-rounded':
            baseStyle += ` bg-btn-primary text-btn-text hover:bg-btn-hover hover:text-btn-hover-text hover:scale-95 rounded-2xl`;
            // baseStyle += ` border bg-transparent border-btn-primary text-btn-primary hover:bg-btn-hover hover:border-btn-hover hover:text-btn-hover-text hover:scale-95 rounded-2xl`;
            // disabledStyle = `disabled:border-btn-disabled disabled:text-btn-disabled-text disabled:dark:bg-gray-900 disabled:dark:text-gray-800 disabled:cursor-not-allowed disabled:hover:scale-100 disabled:hover:scale-100 disabled:hover:bg-transparent`;
            break;
         default:
            break;
      }

      return {
         style: `${baseStyle} ${disabledStyle}`,
      }
   })


   const inputStyle = computed((): { input: string; label: string; } => {
      switch (inputFieldStyle.value) {
         case 'default':
            return textInputDefault;
         case 'filled':
            return textInputFilled;
         case 'filled-rounded':
            return textInputFilledRounded;
         case 'outline':
            return textInputOutline;
         case 'outline-rounded':
            return textInputOutlineRounded;
         default:
            return textInputDefault;
      }
   })

   const dropdownStyle = computed((): { input: string; label: string; } => {
      switch (inputFieldStyle.value) {
         case 'default':
            return fileUploadDefault;
         case 'filled':
            console.log('filled')
            return fileUploadFilled;
         case 'filled-rounded':
            console.log('filled-round')
            return fileUploadFilledRounded;
         case 'outline':
            console.log('outline')
            return fileUploadOutline;
         case 'outline-rounded':
            console.log('outlined rounded')
            return fileUploadOutlineRounded;
         default:
            return fileUploadDefault;
      }
   })
   const dropdownCustomStyle = computed((): { input: string; label: string; classes: {} } => {
      switch (inputFieldStyle.value) {
         case 'default':
            return dropdownCustomDefault;
         case 'filled':
            console.log('filled')
            return dropdownCustomFilled;
         case 'filled-rounded':
            console.log('filled-round')
            return dropdownCustomFilledRounded;
         case 'outline':
            console.log('outline')
            return dropdownCustomOutline;
         case 'outline-rounded':
            console.log('outlined rounded')
            return dropdownCustomOutlineRounded;
         default:
            return dropdownCustomDefault;
      }
   })
   return {
      setInputStyle,
      inputStyle,
      dropdownStyle,
      dropdownCustomStyle,
      checkboxStyle,
      btnStyle,
      inputFieldStyle: readonly(inputFieldStyle),
   }
}
