import axios, { AxiosInstance, AxiosResponse } from 'axios';

export interface PaginatedResponse<T> {
   error: false,
   statusCode: number
   msg?: string;
   total_results: number;
   total_pages: number;
   current_page: number;
   per_page: number;
   results: T[];
}

export interface HttpErrorResponseAttr {
   error: true;
   statusCode: number
   msg: string;
   error_name: string;
   details?: { [key: string]: any }
}

export interface HttpRequestHeaders {
   [key: string]: string;
}

declare module 'axios' {
   interface AxiosResponse<T = any> extends Promise<T & { error: boolean; msg?: string; age?: number; min_age?: number; statusCode: number ; details?: any;}> { }
}

export class HttpClient {
   public readonly httpClient: AxiosInstance;

   public constructor(baseUrl: string, requestHeaders?: HttpRequestHeaders) {
      this.httpClient = axios.create({
         baseURL: baseUrl,
         headers: {
            'Content-Type': 'application/json',
            ...requestHeaders
         },
         // withCredentials: true
      })

      this._initializeResponseInterceptor();
   }

   private _initializeResponseInterceptor = () => {
      this.httpClient.interceptors.response.use(
         this._handleResponse,
         this._handleError,
      );

      this.httpClient.interceptors.request.use(config => {
         let goPfd: string | undefined = undefined;
         if (document.cookie.split('_go_pfd=').length > 1) {
            if (document.cookie.split('_go_pfd=')[1].split(';').length > 1) {
               if (document.cookie.split('_go_pfd=')[1].split(';').length > 1) {
                  goPfd = document.cookie.split('_go_pfd=')[1].split(';')[0];
               }
            } else {
               const lastChar = document.cookie.split('_go_pfd=')[0].charAt(document.cookie.split('_go_pfd=')[0].length - 1);

               if (lastChar === ';') {
                  goPfd = document.cookie.split('_go_pfd=')[0].substring(0, document.cookie.split('_go_pfd=')[0].length - 1);
               }
            }
         }

         let goEid: string | undefined = undefined;
         if (document.cookie.split('_go_eid=').length > 1) {
            if (document.cookie.split('_go_eid=')[1].split(';').length > 1) {
               if (document.cookie.split('_go_eid=')[1].split(';').length > 1) {
                  goEid = document.cookie.split('_go_eid=')[1].split(';')[0];
               }
            } else {
               const lastChar = document.cookie.split('_go_eid=')[0].charAt(document.cookie.split('_go_eid=')[0].length - 1);

               if (lastChar === ';') {
                  goEid = document.cookie.split('_go_eid=')[0].substring(0, document.cookie.split('_go_eid=')[0].length - 1);

               }
            }
         }

         let goFid: | string | undefined = undefined
         if (document.cookie.split('_go_fid=').length > 1) {
            if (document.cookie.split('_go_fid=')[1].split(';').length > 1) {
               if (document.cookie.split('_go_fid=')[1].split(';').length > 1) {
                  goFid = document.cookie.split('_go_fid=')[1].split(';')[0];
               }
            } else {
               const lastChar = document.cookie.split('_go_fid=')[0].charAt(document.cookie.split('_go_fid=')[0].length - 1);

               if (lastChar === ';') {
                  goFid = document.cookie.split('_go_fid=')[0].substring(0, document.cookie.split('_go_fid=')[0].length - 1);

               }
            }
         }

         console.log('goFid: ', goFid)
         if (config.headers) {
            if (goPfd) {
               config.headers['x-go-pfd'] = goPfd;
            }
            if (goEid) {
               config.headers['x-go-eid'] = goEid;
            }

            // if (goFid) {
            //    config.headers['x-go-fid'] = goFid;
            // }
         }

         return config;
      })
   };

   private _handleResponse = ({ data }: AxiosResponse): AxiosResponse & { error: false, statusCode: number } => {
      return {
         error: false,
         ...data
      };
   };

   protected _handleError = (error: any): HttpErrorResponseAttr => {
      let errorData: HttpErrorResponseAttr;

      if (axios.isAxiosError(error)) {
         let msg = error.message;

         if (error.response && error.response.status === 401) {
            window.location.replace('/');
         }

         if (error.response && error.response.data && (error.response.data as any).msg) {
            msg = (error.response.data as any).msg;
         }

         if (error.response && error.response.data && (error.response.data as any).payload) {
            if (Array.isArray((error.response.data as any).payload)) {
               msg = (error.response.data as any).payload.toString();
            } else {
               msg = (error.response.data as any).payload;
            }
         }

         errorData = {
            error: true,
            statusCode: error.response && error.response.status ? error.response.status : 500,
            error_name: `${error.name}: ${error.message}`,
            msg: msg,
            details: error.response || {},
         }
      } else {
         errorData = {
            error: true,
            statusCode: 500,
            error_name: 'Error',
            msg: 'An error occurred while processing the request. Please try again later',
         }
      }

      return errorData
   };
}
