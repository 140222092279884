import { createFingerprint } from '../../../utils/fingerprint';
import { ref, watch, computed, reactive, readonly } from "vue";
import { HttpClient, fontImporter } from "../../../utils";
import { CampaignAttrs, CampaignBrandingAttrs } from "../models";
import useAppTheme from "../../../themes";
import useInputStyles from "./useInputStyles";

const { setTheme } = useAppTheme();
const { setInputStyle } = useInputStyles();
const httpClient = new HttpClient(import.meta.env.VITE_CAMPAIGNS_API_URL, {
   'x-api-token': import.meta.env.VITE_CAMPAIGNS_API_TOKEN
}).httpClient;

const supportedLocales = ref<string[]>([]);
const selectedLocale = ref('en');

if (window.localStorage.getItem('locale')) {
   selectedLocale.value = window.localStorage.getItem('locale')!;
} else {
   window.localStorage.setItem('locale', 'en')
}

const campaign = ref<CampaignAttrs>();
const campaignRedirect = ref('')

export function useCampaigns() {
   const setBranding = (campaignBranding: CampaignBrandingAttrs): void => {
      setTheme({
         backgroundColor: campaignBranding.background_color,
         backgroundImage: campaignBranding.meta.background_image_url
            ? `${campaignBranding.meta.background_image_url}?d=1920x1080`
            : "",
         primary: campaignBranding.primary_color,
         secondary: campaignBranding.secondary_color,
         accent: campaignBranding.accent_color,
         btnPrimary: campaignBranding.btn_color,
         btnText: campaignBranding.btn_text_color,
         btnHover: campaignBranding.btn_hover_color,
         btnHoverText: campaignBranding.btn_hover_text_color,
         textError: campaignBranding.error_font_color,
         textPrimary: campaignBranding.font_color,
      });

      if (campaign.value) {
         campaign.value.branding = campaignBranding;
      }

      setInputStyle(campaignBranding.meta.input_style ? campaignBranding.meta.input_style : 'default');
      setFont(campaignBranding).then(() => {
         // console.log('font loaded')
      });
      return;
   };

   const setFont = async (campaignBranding: CampaignBrandingAttrs): Promise<void> => {
      // import google fonts script
      let fontScriptLoaded = false;
      try {
         await fontImporter.url();

         fontScriptLoaded = true;
      } catch (err) {
         console.log('error importing fonts')
      }

      if (fontScriptLoaded) {
         let fontFamily = campaignBranding.font_family.split(',')[0]

         // @ts-ignore
         window.WebFont.load({
            google: {
               families: [`${fontFamily}:300,400,500,600,700, 800,900`]
            },
            active: function () {
               // console.log('Fonts have been rendered')
               document.documentElement.style.setProperty('--font-family', fontFamily)
            },
            inactive: function (e: any) {
               console.log('Fonts cannot be rendered', e)
            }
         })
      }
   }

   const setCampaign = (data: CampaignAttrs) => {
      if (!campaign.value) {
         campaign.value = {
            id: data.id,
            slug: data.slug,
            start_date: data.start_date,
            end_date: data.end_date,
            live_date: data.live_date,
            branding: {
               logo_url: data.branding.logo_url,
               background_color: data.branding.background_color,
               primary_color: data.branding.primary_color,
               secondary_color: data.branding.secondary_color,
               accent_color: data.branding.accent_color,
               btn_color: data.branding.btn_color,
               btn_text_color: data.branding.btn_text_color,
               btn_hover_color: data.branding.btn_hover_color,
               btn_hover_text_color: data.branding.btn_hover_text_color,
               font_color: data.branding.font_color,
               error_font_color: data.branding.error_font_color,
               font_family: data.branding.font_family,
               font_weight: data.branding.font_weight,
               meta: {
                  default_locale: data.branding.meta.default_locale,
                  entry_code_btn: data.branding.meta.entry_code_btn || 'enter',
                  entry_code_msg: data.branding.meta.entry_code_msg,
                  entry_code_error: data.branding.meta.entry_code_error,
                  exceeded_response: data.branding.meta.exceeded_response,
                  user_max_limit_exceeded_response: data.branding.meta.user_max_limit_exceeded_response,
                  header_image: data.branding.meta.header_image,
                  background_image_url: data.branding.meta.background_image_url,
                  web_marketing_image: data.branding.meta.web_marketing_image,
                  flow_bg_opacity: data.branding.meta.flow_bg_opacity,
                  input_style: data.branding.meta.input_style ? data.branding.meta.input_style : 'default',
               }
            },
            locales: data.locales ? data.locales : ['en'],
         }
      } else {
         campaign.value.id = data.id;
         campaign.value.slug = data.slug;
         campaign.value.start_date = data.start_date;
         campaign.value.end_date = data.end_date;
         campaign.value.live_date = data.live_date;
         campaign.value.branding = {
            logo_url: data.branding.logo_url,
            background_color: data.branding.background_color,
            primary_color: data.branding.primary_color,
            secondary_color: data.branding.secondary_color,
            accent_color: data.branding.accent_color,
            btn_color: data.branding.btn_color,
            btn_text_color: data.branding.btn_text_color,
            btn_hover_color: data.branding.btn_hover_color,
            btn_hover_text_color: data.branding.btn_hover_text_color,
            font_color: data.branding.font_color,
            error_font_color: data.branding.error_font_color,
            font_family: data.branding.font_family,
            font_weight: data.branding.font_weight,
            meta: {
               default_locale: data.branding.meta.default_locale,
               entry_code_btn: data.branding.meta.entry_code_btn,
               entry_code_msg: data.branding.meta.entry_code_msg,
               entry_code_error: data.branding.meta.entry_code_error,
               exceeded_response: data.branding.meta.exceeded_response,
               user_max_limit_exceeded_response: data.branding.meta.user_max_limit_exceeded_response,
               header_image: data.branding.meta.header_image,
               background_image_url: data.branding.meta.background_image_url,
               web_marketing_image: data.branding.meta.web_marketing_image,
               flow_bg_opacity: data.branding.meta.flow_bg_opacity,
               input_style: data.branding.meta.input_style ? data.branding.meta.input_style : 'default',
            }
         };
         campaign.value.locales = data.locales ? data.locales : ['en'];
      }

      supportedLocales.value.splice(0, supportedLocales.value.length);

      if (campaign.value.locales) {
         for (let x = 0; x < campaign.value.locales.length; x++) {
            supportedLocales.value.push(campaign.value.locales[x]);
         }
      }

      return;
   }

   const setSelectedLocale = (value: string) => {
      if (supportedLocales.value.includes(value)) {
         // console.log('setting locale to: ', value)
         selectedLocale.value = value;
         window.localStorage.setItem('locale', value);
      }
   }

   const getCampaignBySlug = async (campaignSlug: string, locale = 'en', setDefaultLocale = false): Promise<boolean> => {
      const result = await httpClient.get<CampaignAttrs>(`/clients/campaigns/${campaignSlug}?locale=${locale}`);
      if (result.statusCode === 307) {
        campaignRedirect.value = result.details.data.payload.redirect_url
         return false;
      } else {
        campaignRedirect.value = ''
      }

      if (result.error) {
         return false;
      }

      const fingerprint = await createFingerprint();
      document.cookie = `_go_fid=${fingerprint}; expires=${new Date(result.end_date)}; path=/; SameSite=None;Secure;`;

      setCampaign(result);

      if (setDefaultLocale && campaign.value && campaign.value.branding.meta.default_locale && campaign.value.branding.meta.default_locale !== '') {
         setSelectedLocale(campaign.value.branding.meta.default_locale);
      }

      return true;
   }

   return {
      getCampaignBySlug,
      campaignRedirect,
      campaign: campaign,
      supportedLocales: readonly(supportedLocales),
      setSelectedLocale,
      selectedLocale: readonly(selectedLocale),
      setBranding,
   }
}
