<template>
	<div
		v-if="isReady"
		id="app"
		class="min-h-screen bg-primary-bg app-container transition-all ease-out duration-500"
	>
		<img
			v-if="campaign?.branding.meta.background_image_url"
			id="bg"
			:src="campaign?.branding.meta.background_image_url"
			alt="campaign"
			class="object-cover h-full"
		/>
		<router-view v-slot="{ Component }">
			<component :is="Component" />
		</router-view>
	</div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { useCampaigns } from './modules/campaigns/composables/useCampaigns';

const { campaign } = useCampaigns();

const setViewHeight = () => {
	let vh = window.innerHeight * 0.01;
	document.documentElement.style.setProperty('--vh', `${vh}px`);
};

const isReady = ref(false);
onMounted(() => {
	console.log('app mounted');
	setViewHeight();
	window.addEventListener('resize', () => {
		// setViewHeight();
	});

	isReady.value = true;
});
</script>
<style lang="css">
:root {
	font-family: var(--font-family);
	background-color: var(--color-bg-primary);
	overflow-y: auto;
}

#bg {
	position: fixed;
	top: 0;
	left: 0;

	/* Preserve aspet ratio */
	min-width: 100%;
	height: 100%;
}
</style>
